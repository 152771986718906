

#allLinksContainer{
  flex-direction:row;
  align-items: flex-start;
  align-self: center;
  padding-top: 20px;
  overflow: scroll;
}




.linkContainer{
/*   
  margin-right: 2%;
  margin-left: 2%; */

  align-items: flex-start;

  display: flex;
  flex-direction: column;
  padding: 0px;
  max-width: 85%;
  width: 85%;

  background-color: rgba(0,10,0,0.6);

  /* min-width: 92%;
  width: 92%;
  max-width: 92%;  */
  border-radius: 30px;
  margin-right: 2%;
  margin-left: 2%;

  max-width: 100%;

}


.linkHeader{
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  min-height: 40px;
  padding-bottom: 5px;
  padding-top: 5px;

  padding-left: 10px;
  padding-right: 10px;
  font-size: 26px;
  /* background-color: #000; */
}


#EssenceOfTheQuote .linkHeader{
  font-family: 'Oswald', 'Montserrat', sans-serif;
  /* color: rgb(74, 44, 0); */

  color: #fff;

  letter-spacing: 0px;

  display: block;
  font-family: Montserrat Alternates,Montserrat,sans-serif;
  font-family: Oswald,Montserrat,sans-serif;
  font-weight: 400;
  letter-spacing: 0;

  
}

#Quesjins .linkHeader{
  color: #fff;
  font-size: 28px;

  text-align: center;

  font-family: 'Playfair Display,sans-serif';
 
}

#Quesjins .linkHeader .titleItalics{
  font-style: italic;
  font-family: Playfair Display Italic,sans-serif;
}

#litintent .linkHeader{
  font-size: 32px;

  color: #bf2525;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

#litHeading{
  color: #ff6000;
  box-sizing: border-box;
}

#intentHeading{
  color: #bf2525;

}



#allLinksContainer a:hover{
  /* background-color: rgba(0,0,0,1); */

}

.appImage{
  background-size: 100% ;
  background-repeat: no-repeat;
  background-position:  top center;
  margin-top: 0;
  align-self: flex-start;
  flex-direction: column;

  margin: 0;
  padding: 0;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.appImage img,.appImage video{
  margin: 0;
  padding: 0;
  min-width: 100%;
  
  width: 100%;
}



div.appBlurb{
  padding: 17px;
  line-height: 28px;
  font-size: 18px;

}



.linkSpacer{
  height: 70px;
  min-height: 70px;
}


video.hidden{
  display: none;
}

img.hidden{
  height: 0%;
  display:none;
}



@media (max-width: 1100px) {
  #allLinksContainer{
    flex-direction: column;
  
  }


  .linkContainer{
    min-width: 85%;
    width: 85%;
    max-width:85%;
    margin-left: auto;
    margin-right: auto;

   
  }
}